import React from 'react';
import {Link} from 'gatsby';
import {FooterMenuWrap, FooterMenuList} from './footer-menu.stc'

const FooterMenu = () => {
    return (
        <FooterMenuWrap>
            <FooterMenuList>
                <Link to="/company" className="no-cursor">О компании</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/project" className="no-cursor">Кейсы</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/blog" className="no-cursor">Блог</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/contact" className="no-cursor">Контакты</Link>
            </FooterMenuList>
            {/* <FooterMenuList>
                <Link to="/blog-5" className="no-cursor">Создание квиз-лендингов</Link>
            </FooterMenuList> */}
            {/* <FooterMenuList>
                <Link to="/blog-6" className="no-cursor">Аналитика</Link>
            </FooterMenuList> */}
            {/* <FooterMenuList>
                <Link to="/blog-4" className="no-cursor">Внедрение CRM</Link>
            </FooterMenuList> */}
        </FooterMenuWrap>
    )
}

export default FooterMenu;